import { Box, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import ModalDialog from './dialog/ModalDialog';

function Sample() {

  // ダイアログ用のstate
  const [digOpen, setDigOpen] = useState(false);

  return (
    <div>
      <ModalDialog
        title="タイトル"
        open={digOpen}
        onClose={() => setDigOpen(false)}>
        <Box sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <TextField
              label="ID"
              variant="filled"
            />
            <TextField
              label="Password"
              variant="filled"
            />
          </Stack>
          <Box sx={{ mt: 3 }}>
            <Button variant="contained">ログイン</Button>
          </Box>
        </Box>
      </ModalDialog>
      <Button onClick={() => setDigOpen(true)}>
        モーダル表示
      </Button>
    </div>
  );
}

export default Sample;
