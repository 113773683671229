import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog, DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * タイトルエリアのスタイル
 */
const titleStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: '#333',
  '& *': {
    fontWeight: 'bold',
    color: '#fff'
  }
}

function ModalDialog(props: any) {

  // プロパティ
  const {
    title,
    onClose,
    open,
    maxWidth,
  } = props;

  // ダイアログ表示状態
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // openの値が変化した時
  useEffect(() => setDialogOpen(open), [open]);

  // ダイアログクローズ
  const handleClose = () => {
    setDialogOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={dialogOpen}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={maxWidth}>
      <DialogTitle sx={{ ...titleStyle }}>
        <span>{title}</span>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}

// プロパティ
ModalDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.element,
  maxWidth: PropTypes.string
};

export default ModalDialog;
