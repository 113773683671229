import { EmojiSymbolsTwoTone } from '@mui/icons-material';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MessageDialog from '../dialog/MessageDialog';
import { APP_PAGE_TILE } from '../redux/modules/app';
import { RootState } from '../redux/modules/root';
import { Message, RECEIVE_LIST } from '../redux/modules/user';

/**
 * 受信
 */
function Setting() {

  const dispatch = useDispatch();

  // 初期表示
  useEffect(() => {
    dispatch(({ type: APP_PAGE_TILE, payload: "設定など" }));
  }, []);

  return (
    <Box sx={{ m: 2}}>
      <Stack>
        <Box>
          <Typography variant="h6">・LINE友達追加（メール受信通知用）</Typography>
          <Box sx={{ typography: 'body2', mt: 2 }}>
            以下のQRコードからLINEのお友達を追加すると、ユーザーからのメールを受信した時に LINEで通知を送ります。通知する内容には個人情報（氏名・メールアドレス）を含む情報を通知するため、このQRコードは管理者のみが使用し、一般のユーザーには教えないようにしてください。
          </Box>
          <Box>
            <img src={process.env.PUBLIC_URL + "/LINE_QR.png"}/>
          </Box>
        </Box>

      </Stack>
    </Box>
  );
}

export default Setting;
