import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  AlertColor, Autocomplete,
  Box,
  Container,
  CssBaseline, Snackbar, Stack,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTextField } from '../common/CustomTextField';
import CommonDialog, { ButtonType } from '../dialog/CommonDialog';
import { useSendMail } from '../hooks/useSendMail';
import { RootState } from '../redux/modules/root';
import { User } from '../redux/modules/user';

function Send() {
  console.log("[ST]SEND")

  // redux系の変数
  const users = useSelector((state: RootState) => state.send.users);
  const usersLoding = useSelector((state: RootState) => state.send.loading);

  // ユーザーの選択状態を管理するリスト
  const [selectedOptions, setSelectedOptions] = useState(new Array<User>());

  // Snackbar
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    severity: "success" as AlertColor,
    message: ""
  });

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  // ダイアログ用のstate
  const [dig, setDig] = useState({ open: false, message: "" });

  // メール送信画面のカスタムフック
  const [
    sending, 
    errors, 
    isValid, 
    sendMail
  ] = useSendMail(selectedOptions, subject, body)

  // 宛先変更時のイベント
  const handleChange = (event: any, value: User[]) => setSelectedOptions(value);

  // 宛先に選択された人数を返す
  const sendUserCount = () => selectedOptions.find(n => n.id == "ALL") 
    ? users.length : selectedOptions.length;

  // メール送信処理（送信ボタン押下時） 
  const handleSend = () => {
    if (isValid()) {
      setDig({ open: true, message: `${sendUserCount()}人にメールを送信します。\n宜しいですか？` });
    }
  };

  // 送信開始
  const handleSendExecut = () => {
    
    sendMail().subscribe({
      next: (data) => {
        setOpenSnackbar({
          open: true,
          severity: data.success ? "success" : "error",
          message: data.message
        });
      }
    });
  };

  return (
    <Box>
      <Container component="main">
        <Box>
          <Stack spacing={2} sx={{ mt: "2ch" }}>
            <div>
              <Autocomplete
                multiple
                id="tags-standard"
                options={users}
                getOptionLabel={(option) => option.UserName}
                defaultValue={[]}
                loading={usersLoding}
                loadingText="読み込み中..."
                onChange={handleChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.UserName} ({option.MailAddress})
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={"to" in errors}
                    label="宛先"
                    placeholder="宛先を入力"
                  />
                )}
              />
              {errors["to"] && (
                <p className="error">{errors["to"]}</p>
              )}
            </div>
            <div>
              <CustomTextField
                id="standard-basic"
                variant="filled"
                error={"subject" in errors}
                label="件名"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
              {errors["subject"] && (
                <p className="error">{errors["subject"]}</p>
              )}
            </div>
            <div>
              <CustomTextField
                id="outlined-multiline-flexible"
                label="本文"
                error={"body" in errors}
                multiline
                variant="filled"
                minRows={10}
                maxRows={30}
                value={body}
                onChange={e => setBody(e.target.value)}
              />
              {errors["body"] && (
                <p className="error">{errors["body"]}</p>
              )}
            </div>
          </Stack>
          <Stack
            sx={{ mt: "4ch" }}
            direction="row">
            <LoadingButton
              onClick={handleSend}
              endIcon={<SendIcon />}
              loading={sending}
              loadingPosition="end"
              size="large"
              variant="contained"
            >
              {sending
                ? <span>送信中（送信完了までそのままで...）</span>
                : <span>送信</span>
              }
            </LoadingButton>
          </Stack>
        </Box>
      </Container>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={openSnackbar.open}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar({...openSnackbar, open: false})}>
        <Alert 
          onClose={() => setOpenSnackbar({...openSnackbar, open: false})} 
          severity={openSnackbar.severity} sx={{ width: '100%' }}>
          {openSnackbar.message}
        </Alert>
      </Snackbar>
      
      <CommonDialog
        title="送信確認"
        message={dig.message}
        buttonType={ButtonType.YesNo}
        open={dig.open}
        onAccept={handleSendExecut}
        onClose={() => setDig({...dig, open: false})}
      />
    </Box>
  );
}

export default Send;
