import { act } from "react-dom/test-utils";
import { ofType, StateObservable } from "redux-observable";
import { concat, map, mergeMap, of, interval, takeWhile, finalize } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isJSDocReturnTag } from "typescript";
import { AwsApiClient } from "../../util/AwsApiClient";
import { RootState } from "./root";
import { User } from "./user";

// interface
export interface SendState {
  users: User[],
  loading: boolean,
}

// ユーザー一覧取得 アクションクリエイター
export const SEND_FETCH_USERS = "send/FETCH_USERS";
export const SEND_FETCH_USERS_FULFILLED = "send/FETCH_USERS_FULFILLED";

// ローディング状態更新 アクションクリエイター
export const SET_LODING_STATE = "send/SET_LODING_STATE";

// initial state
const initialState: SendState = {
  users: new Array<User>(),
  loading: false
};

// reducer
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEND_FETCH_USERS_FULFILLED:
      //console.log("send.ts reducer", action.payload)
      return { ...state, users: action.payload }
    case SET_LODING_STATE:
      //console.log("loading", action.payload)
      return { ...state, loading: action.payload }
    default:
      return state
  }
};

// ユーザー一覧取得
export const sendUsersFetchEpic = (action$: any, state$: any) => action$.pipe(
  ofType(SEND_FETCH_USERS),
  mergeMap(action =>
    concat(
      of({ type: SET_LODING_STATE, payload: true }),
      AwsApiClient.getUsers(true, true, state$.value.app.authHash)
        .pipe(
          map(rs => rs.response),
          map(response => ({ type: SEND_FETCH_USERS_FULFILLED, payload: response }))
        ),
      of({ type: SET_LODING_STATE, payload: false }),
    )
  )
);

export default reducer;