import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { catchError, finalize, map, Observable, of } from "rxjs";
import { FieldErrors } from "../dm/Models";
import { APP_PAGE_TILE } from "../redux/modules/app";
import { RootState } from "../redux/modules/root";
import { SEND_FETCH_USERS } from "../redux/modules/send";
import { User } from "../redux/modules/user";
import { AwsApiClient, MailMessage } from "../util/AwsApiClient";

/**
 * メール送信画面のカスタムフック
 * @param selectedUsers 選択ユーザーリスト
 * @param subject 件名 
 * @param body 本文
 * @returns [送信中状態、エラーリスト、入力チェック、メール送信処理]
 */
export const useSendMail = (
  selectedUsers: User[],
  subject: string,
  body: string
): [
    boolean,
    FieldErrors,
    () => boolean,
    () => Observable<SendResult>,
  ] => {

  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.send.users);
  const appState = useSelector((state: RootState) => state.app);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as FieldErrors);

  // 初期表示
  useEffect(() => {
    //console.log("user loding...");
    dispatch(({ type: APP_PAGE_TILE, payload: "メール送信" }));
    dispatch(({ type: SEND_FETCH_USERS }));
    console.log("[ST]useEffect")
  }, []);

  /**
   * 入力チェック
   * @returns チェック結果
   */
  const isValid = (): boolean => {
    const err: FieldErrors = {};
    let valid = true;
    if (selectedUsers.length == 0) {
      err["to"] = "宛先が指定されていません。";
      valid = false;
    }
    if (!subject) {
      err["subject"] = "件名が指定されていません。";
      valid = false;
    }
    if (!body) {
      err["body"] = "本文が指定されていません。";
      valid = false;
    }
    //入力エラー情報の設定
    setErrors(err);
    return valid;
  };

  /**
   * メール送信
   */
  const sendMail = (): Observable<SendResult> => {

    let toList = selectedUsers.map(n => ({
      address: n.MailAddress,
      name: n.UserName
    }));

    // ALLを選択している場合は、全員に送信
    let allSend = toList.filter(n => n.address == "ALL").length >= 1;
    if (allSend) {
      toList = users.filter(n => n.MailAddress != "ALL").map(n => ({
        address: n.MailAddress,
        name: n.UserName
      }));
    }

    const msg: MailMessage = {
      to: toList,
      allSend: allSend,
      subject: subject,
      body: body
    };

    setLoading(true);

    //メール送信
    return AwsApiClient.sendMail(msg, appState.authHash).pipe(
      map(rs => {
        //console.log(rs.response);
        return { success: true, message: "送信完了" }
      }),
      catchError(err => {
        console.log("error", err);
        return of({ success: false, message: err?.response["message"] ?? "ERROR" });
      }),
      finalize(() => {
        setLoading(false);
      })
    );
  }

  return [loading, errors, isValid, sendMail];
};


/**
 * メール送信結果
 */
export interface SendResult {
  success: boolean,
  message: string
}